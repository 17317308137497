import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { Switch, Redirect, Route, useLocation } from 'react-router';
import * as Sentry from '@sentry/react';
import {
  PATHS,
  AUTH,
  PATH_SEGMENTS,
  TOGGLES,
  FEATURES,
} from '@team-seenit/constants';

import {
  AppProvider,
  UserProvider,
  useAuth,
  useAnalytics,
  ToggleProvider,
  useToggle,
  useUser,
} from '@team-seenit/contexts';
import {
  CompleteInvitePage,
  EditStudioDetailsPage,
  GetStartedPage,
  InspirationHubPage,
  ProjectCreatePage,
  ProjectFinderPage,
  UploadPage,
  NewUserWelcomePage,
  NotFoundPage,
  NoAccessPage,
  LoginPage,
  LoginCallbackPage,
  LogoutPage,
  LogoutCallbackPage,
  PeoplePage,
  LibraryPage,
  QuickEditPage,
  QuickEditsPage,
  Homepage,
} from '@team-seenit/pages';
import { QuickEditsBriefPage } from '@team-seenit/experiments';
import { SkipToButton } from '@team-seenit/atoms';

import {
  CloudinaryCookieHelper,
  FeatureModal,
  PageAccess,
} from '@team-seenit/organisms';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datepicker/dist/react-datepicker.css';

import { createApolloClient, envConfig } from './config';
import ProjectRoutes from './ProjectRoutes';
import Prerender from './components/Prerender';

const { USER_ID_KEY } = AUTH;
const config = envConfig();

// eslint-disable-next-line react/prop-types
const StudioRoute = ({ children, ...props }) => {
  const { isAuthenticated, isProjectCreator, isQuickEditMaker } = useAuth();

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      render={() => {
        if (!isAuthenticated || !(isProjectCreator || isQuickEditMaker)) {
          return <GetStartedPage />;
        }

        return (
          <>
            <CloudinaryCookieHelper />
            <PageAccess />
            {children}
          </>
        );
      }}
    />
  );
};

const HomePageRedirect = () => {
  const { isQuickEditMaker, isOrgAdmin, isProjectCreator } = useAuth();
  const { isToggleEnabled, loading: toggleLoading } = useToggle();
  const { detailsForFeature, loading: userLoading } = useUser();

  const isHomepageEnabled = isToggleEnabled(TOGGLES.HOMEPAGE);

  let to = PATHS.PROJECT_FINDER;

  if (isQuickEditMaker && !(isOrgAdmin || isProjectCreator)) {
    to = PATHS.QUICK_EDITS;
  } else if (
    detailsForFeature(FEATURES.HOMEPAGE).isAllowed &&
    isHomepageEnabled
  ) {
    to = PATHS.TEMP_HOME;
  }

  if (toggleLoading || userLoading) return null;

  return <Redirect to={to} />;
};

const App = () => {
  const { search, pathname } = useLocation();
  const {
    loading,
    user: { email, [USER_ID_KEY]: userId } = {},
    user,
    accessToken,
  } = useAuth();

  const { identifyTracker, IDENTITIES } = useAnalytics();

  useEffect(() => {
    if (userId && email) {
      identifyTracker(IDENTITIES.EXISTING_USER_PAGE, { userId, email });
    }
  }, [userId, email, IDENTITIES.EXISTING_USER_PAGE, identifyTracker]);

  if (loading) return '';

  const apolloClient = createApolloClient({ logger: console, accessToken });
  /* eslint-enable */

  return (
    <AppProvider
      tvDomain={config.tvDomain}
      googleVertexProxyEndpoint={config.googleVertexProxyEndpoint}
    >
      <ApolloProvider client={apolloClient}>
        <UserProvider user={user}>
          <ToggleProvider>
            <Prerender>
              <FeatureModal />
              <SkipToButton />
              <Switch>
                <Redirect
                  from={`/${PATH_SEGMENTS.PROJECTS_OLD_BASE}*`}
                  to={`${pathname.replace(
                    PATH_SEGMENTS.PROJECTS_OLD_BASE,
                    PATH_SEGMENTS.PROJECTS
                  )}${search}`}
                />

                <Route path={PATHS.LOGOUT}>
                  <LogoutPage />
                </Route>

                <Route path={PATHS.LOGIN}>
                  <LoginPage />
                </Route>

                <Route exact path={PATHS.LOGIN_CALLBACK}>
                  <LoginCallbackPage />
                </Route>

                <Route exact path={PATHS.LOGOUT_CALLBACK}>
                  <LogoutCallbackPage />
                </Route>

                {/* Backwards compatibility purposes */}
                <Route exact path={PATHS.GET_STARTED}>
                  <Redirect to={PATHS.HOME} />
                </Route>

                <StudioRoute exact path={PATHS.HOME}>
                  <HomePageRedirect />
                </StudioRoute>

                <Route exact path={PATHS.NO_ACCESS_PAGE}>
                  <NoAccessPage />
                </Route>

                <Route path={PATHS.COMPLETE_INVITE}>
                  <CompleteInvitePage />
                </Route>

                <Route exact path={PATHS.JOIN_PROJECT}>
                  <CloudinaryCookieHelper />
                  <UploadPage />
                </Route>

                <Route path={PATHS.NEW_USER_WELCOME}>
                  <NewUserWelcomePage />
                </Route>

                <StudioRoute exact path={PATHS.PROJECT_FINDER}>
                  <ProjectFinderPage />
                </StudioRoute>

                <StudioRoute exact path={PATHS.PROJECT_CREATE}>
                  <ProjectCreatePage />
                </StudioRoute>

                <StudioRoute exact path={PATHS.INSPIRATION_HUB}>
                  <InspirationHubPage />
                </StudioRoute>

                <StudioRoute exact path={PATHS.STUDIO_SETTINGS}>
                  <EditStudioDetailsPage />
                </StudioRoute>

                <StudioRoute exact path={PATHS.PEOPLE}>
                  <PeoplePage />
                </StudioRoute>

                <StudioRoute exact path={PATHS.LIBRARY}>
                  <LibraryPage />
                </StudioRoute>

                <StudioRoute exact path={PATHS.QUICK_EDITS}>
                  <QuickEditsPage />
                </StudioRoute>

                <StudioRoute
                  exact
                  path={[
                    PATHS.QUICK_EDITS_CREATE,
                    PATHS.QUICK_EDIT_BRIEF,
                    PATHS.QUICK_EDIT_EDITS_BRIEF,
                  ]}
                >
                  <QuickEditsBriefPage />
                </StudioRoute>

                <StudioRoute exact path={PATHS.QUICK_EDITS_TWO}>
                  <QuickEditPage />
                </StudioRoute>

                <StudioRoute path={PATHS.PROJECT_HOME}>
                  <ProjectRoutes />
                </StudioRoute>

                <StudioRoute path={PATHS.TEMP_HOME}>
                  <Homepage />
                </StudioRoute>

                <Route path="*">
                  <NotFoundPage />
                </Route>
              </Switch>
            </Prerender>
          </ToggleProvider>
        </UserProvider>
      </ApolloProvider>
    </AppProvider>
  );
};

export default Sentry.withProfiler(App);

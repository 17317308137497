import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { matchPath, useLocation } from 'react-router';

import { PATHS } from '@team-seenit/constants';

const Prerender = ({ children }) => {
  const location = useLocation();

  // Prerender.io finished rendering for all but the uploader path
  useEffect(() => {
    const path = matchPath(location.pathname, {
      path: PATHS.JOIN_PROJECT,
      exact: true,
    });
    if (!path) window.prerenderReady = true;
  }, [location]);

  return children;
};

Prerender.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default Prerender;

import React from 'react';
import {
  Switch,
  Redirect,
  Route,
  useRouteMatch,
  useLocation,
} from 'react-router';
import { PATHS } from '@team-seenit/constants';
import {
  BriefPage,
  CollatePage,
  EditPage,
  NotFoundPage,
  WeVideoEditingPage,
  WevideoEditingWithEditIdPage,
  SmartEditorPage,
} from '@team-seenit/pages';

export default () => {
  const { search } = useLocation();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={path} to={PATHS.PROJECT_COLLATE} />

      <Redirect
        from={PATHS.PROJECT_BRIEF_OLD}
        to={`${PATHS.PROJECT_BRIEF}${search}`}
      />

      <Route exact path={[PATHS.PROJECT_BRIEF]}>
        <BriefPage />
      </Route>

      <Route exact path={PATHS.PROJECT_COLLATE}>
        <CollatePage />
      </Route>

      <Route exact path={PATHS.PROJECT_EDIT}>
        <EditPage />
      </Route>

      <Route exact path={`${PATHS.PROJECT_EDIT}/:editId`}>
        <EditPage />
      </Route>

      <Route exact path={`${PATHS.PROJECT_SMART_EDITOR_EDIT_ID}`}>
        <SmartEditorPage />
      </Route>

      <Route exact path={PATHS.PROJECT_EDITOR}>
        <WeVideoEditingPage />
      </Route>

      <Route exact path={PATHS.PROJECT_EDITOR_EDIT_ID}>
        <WevideoEditingWithEditIdPage />
      </Route>

      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
};
